import React, { useState, useRef } from "react";
import ToggleButton from "./ToggleButton";
import "./App.css";

function App() {
  const initialState = {
    dishName: "",
    dishPrice: "",
    dishCuisine: "american",
    dishPicture: null,
    dishDescription: "",
    restaurantName: "",
    restaurantAddress: "",
    dishCourse: "lunch",
    sweet: 0,
    spicy: 0,
    beef: 0,
    chicken: 0,
    fish: 0,
    noProtein: 0,
    dry: 0,
    frozen: 0,
  };
  const fileInputRef = useRef();
  const [formData, setFormData] = useState(initialState);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files.length > 0 ? event.target.files[0] : null;
    setFormData((prevState) => ({ ...prevState, dishPicture: file }));
    if (file) {
      setImagePreviewUrl(URL.createObjectURL(file));
    } else {
      setImagePreviewUrl(null);
    }
  };

  const handleToggleChange = (name, value, event) => {
    event.persist();
    const newValue = formData[name] === value ? "" : value;
    setFormData((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const url = "https://hungrybudget.com/endpoints/newDishSuggestion.php";
    const data = new FormData();

    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value);
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        setFormData({ ...initialState, dishPicture: null });
        fileInputRef.current.value = "";
        setIsFormSubmitted(true);
        setTimeout(() => {
          setIsFormSubmitted(false);
        }, 1000);
        alert("Thank you for your suggestion! We will review it shortly 😊");
      } else {
        alert(
          "Something went wrong 😞. Please refresh the page and try again."
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const breakfastToggles = [
    { name: "salty", label: "Salty" },
    { name: "sweet", label: "Sweet" },
    { name: "spicy", label: "Spicy" },
    { name: "egg", label: "Egg" },
    { name: "fruit", label: "Fruit" },
  ];
  const lunchDinnerToggles = [
    { name: "salty", label: "Salty" },
    { name: "sweet", label: "Sweet" },
    { name: "spicy", label: "Spicy" },
    { name: "beef", label: "Beef" },
    { name: "pork", label: "Pork" },
    { name: "chicken", label: "Chicken" },
    { name: "fish", label: "Fish" },
    { name: "noProtein", label: "No Protein" },
  ];
  const dessertToggles = [
    { name: "salty", label: "Salty" },
    { name: "sweet", label: "Sweet" },
    { name: "spicy", label: "Spicy" },
    { name: "dry", label: "Dry" },
    { name: "frozen", label: "Frozen" },
  ];

  const renderToggleButtons = () => {
    switch (formData.dishCourse) {
      case "breakfast":
        return breakfastToggles.map((toggle) => (
          <ToggleButton
            key={toggle.name}
            id={toggle.name}
            name={toggle.name}
            label={toggle.label}
            onToggleChange={handleToggleChange}
            isFormSubmitted={isFormSubmitted}
          />
        ));
      case "lunch":
      case "dinner":
        return lunchDinnerToggles.map((toggle) => (
          <ToggleButton
            key={toggle.name}
            id={toggle.name}
            name={toggle.name}
            label={toggle.label}
            onToggleChange={handleToggleChange}
            isFormSubmitted={isFormSubmitted}
          />
        ));
      case "dessert":
        return dessertToggles.map((toggle) => (
          <ToggleButton
            key={toggle.name}
            id={toggle.name}
            name={toggle.name}
            label={toggle.label}
            onToggleChange={handleToggleChange}
            isFormSubmitted={isFormSubmitted}
          />
        ));
      default:
        return null;
    }
  };

  return (
    <div className="new-dish-wrapper">
      <h2 style={{ textAlign: "center" }}>New Dish Suggestion</h2>
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="dishName">Dish Name*</label>
          <input
            type="text"
            id="dishName"
            name="dishName"
            placeholder="Ex. Amazing Hot Dog"
            value={formData.dishName}
            onChange={handleInputChange}
            maxLength={25}
            required
          />
        </div>
        <div>
          <label htmlFor="dishPrice">Dish Price*</label>
          <input
            type="number"
            step="0.01"
            id="dishPrice"
            name="dishPrice"
            placeholder="$$$"
            maxLength={3}
            value={formData.dishPrice}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="cuisineForm">
          <label htmlFor="dishCourse">Meal*</label>
          <select
            className="cuisineSelect"
            id="ddishCourse"
            name="dishCourse"
            value={formData.dishCourse}
            onChange={handleInputChange}
            required
          >
            <option value="breakfast">Breakfast</option>
            <option value="lunch">Lunch/Dinner</option>
            <option value="dessert">Dessert</option>
          </select>{" "}
        </div>

        <div className="booleansSection">
          <h4>Select All That Apply</h4>
          <div className="booleans">{renderToggleButtons()}</div>
        </div>

        <div className="cuisineForm">
          <label htmlFor="dishCuisine">Dish Cuisine*</label>
          <select
            className="cuisineSelect"
            id="dishCuisine"
            name="dishCuisine"
            value={formData.dishCuisine}
            onChange={handleInputChange}
            required
          >
            <option value="american">American</option>
            <option value="spanish">
              Spanish (Mexican, Chilean, Peruvian, etc.)
            </option>
            <option value="italian">Italian</option>
            <option value="chinese">Chinese</option>
            <option value="japanese">Japanese</option>
            <option value="mediterranean">Mediterranean</option>
            <option value="thai">Thai</option>
            <option value="indian">Indian</option>
            <option value="korean">Korean</option>
            <option value="greek">Greek</option>
            <option value="vietnamese">Vietnamese</option>
          </select>{" "}
        </div>

        <div>
          <label htmlFor="dishPicture">Dish Picture*</label>
          <input
            className="fileInput"
            type="file"
            id="dishPicture"
            accept="image/png, image/jpeg"
            name="dishPicture"
            placeholder="Upload dish picture"
            onChange={handleFileChange}
            ref={fileInputRef}
            required
          />
          {imagePreviewUrl && (
            <img
              className="imagePreview"
              src={imagePreviewUrl}
              alt="Dish Preview"
            />
          )}
        </div>

        <div className="descriptionForm">
          <label htmlFor="dishDescription">Dish Description*</label>
          <textarea
            className="descriptionInput"
            id="dishDescription"
            name="dishDescription"
            placeholder="Dish Ingredients / Description"
            value={formData.dishDescription}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="descriptionForm">
          <label htmlFor="restaurantName">Restaurant Name*</label>
          <input
            className="descriptionInput"
            type="text"
            id="restaurantName"
            name="restaurantName"
            placeholder="Ex. Hot Dog Restaurant"
            value={formData.restaurantName}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="descriptionForm">
          <label htmlFor="restaurantAddress">Restaurant Address*</label>
          <input
            className="descriptionInput"
            type="text"
            id="restaurantAddress"
            name="restaurantAddress"
            placeholder="Ex. 123 Test Street"
            value={formData.restaurantAddress}
            onChange={handleInputChange}
            required
          />
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default App;
